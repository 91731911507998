<template>
  <ion-page>
    <syaberu-menu/>
    <ion-content fullscreen>
      <ion-list>
          <ion-item 
            v-bind:key="invoice.year+invoice.month"
            :id="invoice.year+invoice.month" 
             v-for="(invoice,index) of invoices">
              <ion-checkbox
                 slot="start"
                 @click="calculate(invoice,index,$event.target.checked)"/> 
            <ion-label>
              <h4>{{invoice.year}}年{{invoice.month}}月</h4>
              <h1><strong>{{getCommaSeparatedPrice(invoice.sum)}}円</strong></h1>
            </ion-label>
            <ion-label>
              <ion-grid>
                <ion-row class="rightAligned">
                  <ion-col><small>給食費</small></ion-col>
                  <ion-col><h4>{{getCommaSeparatedPrice(invoice.meal)}}円</h4></ion-col>
                </ion-row>
                <ion-row class="rightAligned">
                  <ion-col><small>延長保育料</small></ion-col>
                  <ion-col><h4>{{getCommaSeparatedPrice(invoice.extend)}}円</h4></ion-col>
                </ion-row>
                <ion-row class="rightAligned">
                  <ion-col><small>購入備品</small></ion-col>
                  <ion-col><h4>{{getCommaSeparatedPrice(invoice.purchasedProduct)}}円</h4></ion-col>
                </ion-row>
              </ion-grid>
            </ion-label>
          </ion-item>
      </ion-list>
   </ion-content>
   <ion-footer>
     <ion-toolbar>
        <ion-title>総額:  {{getCommaSeparatedPrice(sum())}} 円 </ion-title>
     </ion-toolbar>
     <ion-button id="card-button" :disabled="canPost()" @click="sendToCredit()" expand="full">クレジットカードの入力に進む</ion-button>       
   </ion-footer>
  </ion-page>
</template>
<script>

const FUNCTIONS_HOST_NAME = 'https://us-central1-attendancerecorder-admin.cloudfunctions.net';
const OUTSTANDING_PAYMENT_URL = FUNCTIONS_HOST_NAME+'/payment/outstanding';

import { IonPage, 
IonButton,
IonList,
IonItem,
IonCheckbox,
IonLabel,
IonFooter,
IonGrid,
IonRow,
IonCol,
IonToolbar,
IonContent,
IonTitle} from '@ionic/vue';
import  SyaberuMenu from './SyaberuMenu.vue';
export default  {
  name: 'Payment',
  components: { IonPage,
  IonButton,
  IonList,
  IonItem,
  IonCheckbox,
  IonLabel,
  IonFooter,
  IonGrid,
  IonRow,
  IonCol,
  SyaberuMenu,
  IonToolbar,
  IonContent,
  IonTitle },
  data(){
    return {
      invoices : [],
      selectedIndex : {},
      amount : 0,
      userId : null,
      tenantId : null,
      disabled : true
    }
  },
  async mounted(){
    this.$root.startLoading();
    this.tenantId = this.$store.state.activeUser.tenantId;
    this.userId   = this.$store.state.activeUser.userId;
    await this.loadInvoices();
    this.$root.endLoading();
  },
  methods : {
   sum() {
      let sum = 0;
      for (const index in this.selectedIndex) {
        console.log(index);
        sum += this.invoices[index].sum;
      }
      this.amount = sum;
      return sum;    
    },
    calculate(invoice,index,notChecked){
      if(!notChecked){
        this.selectedIndex[index] = index;
      }else{
        delete this.selectedIndex[index];
      }
    },
    async loadInvoices(){
      let res = await this.axios.get(OUTSTANDING_PAYMENT_URL, {params:{
        "tenantId" : this.tenantId, 
        "userId" : this.userId
      }});
      let invoices = res.data;
      if(invoices.length > 0){
        this.invoices = invoices;
      }
    },
    /**
     * @function
     * @return{string}
     * @param{number} num  3000
     * @param{string} "3,000"
     */
    getCommaSeparatedPrice(num){
      return num ? num.toLocaleString() : "0";
    },
    canPost(){
      //100円以下は決済出来ない
      return this.amount < 100;
    },
    sendToCredit(){
     let serializedInvoices = [];
     this.invoices.forEach(element => {
       serializedInvoices.push(JSON.stringify(element));
     });     
    const parameter = {
          amount : this.amount,
          invoices : serializedInvoices,
          selectedIndex: JSON.stringify(this.selectedIndex)
      };
      //props: trueにするという魔法をかけている
      this.$router.push({
        name : "/creditCardInput",
        params : parameter
      });
    }
  }
}
</script>
<style>
.rightAligned{
  text-align: right;
}
</style>